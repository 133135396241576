'use client'

import { Button } from 'fukku/Button'
import { useContext, useEffect, useRef } from 'react'

import { MarqueeContext } from '../../../../../components/Marquee/MarqueeProvider'

import styles from './HeroBannerPrimaryLines.module.scss'

interface AnimationButtonProps {
	titles: {
		play: string
		pause: string
	}
}

export const AnimationButton = ({ titles }: AnimationButtonProps) => {
	const { isAnimationPaused, toggleAnimation } = useContext(MarqueeContext)
	const buttonRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		function handleMouseDown(e: MouseEvent) {
			e.stopPropagation()
		}

		const element = buttonRef?.current
		if (element) {
			element.addEventListener('mousedown', handleMouseDown)
		}

		return () => {
			if (element) {
				element.removeEventListener('mousedown', handleMouseDown)
			}
		}
	}, [buttonRef])

	return (
		<div ref={buttonRef}>
			<Button
				onClick={toggleAnimation}
				className={styles.pauseButton}
				icon={isAnimationPaused ? 'play-s' : 'pause-s'}
				buttonAttributes={{
					title: isAnimationPaused ? titles.play : titles.pause,
				}}
			/>
		</div>
	)
}
