'use client'

import { Tooltip } from 'fukku/Tooltip'
import { IconInfoL } from 'icons/components/IconInfoL'
import { useRef } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerInfoTooltip.module.scss'

interface HeroBannerInfoTooltipProps {
	title: string
	inverse: boolean
	showInfo: boolean
	infoText: string
}

export function HeroBannerInfoTooltip({
	title,
	inverse,
	showInfo,
	infoText,
}: HeroBannerInfoTooltipProps) {
	const boundaryRef = useRef(null)

	if (!showInfo) {
		return <></>
	}

	return (
		<div ref={boundaryRef} className={styles.boundary}>
			<Tooltip
				position='top'
				content={<p>{infoText}</p>}
				boundary={boundaryRef}
			>
				<button
					title={title}
					className={cx(
						animationStyles.opacityFadeIn,
						styles.infoIconButton,
						applyStylesIf(inverse, styles.inverse)
					)}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}
				>
					<IconInfoL />
				</button>
			</Tooltip>
		</div>
	)
}
