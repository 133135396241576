'use client'

import { type ReactNode, useRef } from 'react'

import { BannerWrapper } from '../../../BannerWrapper/BannerWrapper'

interface FamilyBannerRootWrapperProps {
	isPrioritySlot: boolean
	children: ReactNode
	className?: string
}

export function FamilyBannerRootWrapper({
	isPrioritySlot,
	children,
	className,
}: FamilyBannerRootWrapperProps) {
	const ref = useRef<HTMLDivElement>(null)

	return (
		<BannerWrapper ref={ref} priority={isPrioritySlot} className={className}>
			{children}
		</BannerWrapper>
	)
}
