import { cx } from 'utils/cx'

import {
	type HeroBannerPromo,
	HeroBannerPromoType,
} from '../../../../Home.type'

import styles from './HeroBannerPromoGeneral.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface HeroBannerPromoGeneralProps {
	section: HeroBannerPromo
}

export function HeroBannerPromoGeneral({
	section,
}: HeroBannerPromoGeneralProps) {
	if (section.promoType !== HeroBannerPromoType.general) {
		return null
	}

	const {
		promoTitles: { title, discount },
	} = section

	return (
		<div className={cx(styles.titles, text.inspirationalTitleL)}>
			<h2 className={styles.titlesWrapper}>
				<div>{title}</div>
				<div>{discount}</div>
			</h2>
		</div>
	)
}
