import {
	getFromLocalStorage,
	removeFromLocalStorage,
} from 'utils/webStorage/localStorage'
import type { Stored } from 'utils/webStorage/types'

import { type Order, OrderStatusWithIcon } from '../../types/order/order'
import type { PurchaseDetail } from '../../types/purchase/purchase'
import { LOCAL_STORAGE_BANNER_KEY } from '../constants'

/**
 * This method checks if order banner alert was shown and closed by the user
 */
export function hasAlertBeenClosed(orders: Order[]) {
	const storedPurchaseDetails = getFromLocalStorage(
		LOCAL_STORAGE_BANNER_KEY
	) as Stored<PurchaseDetail | null>

	if (storedPurchaseDetails) {
		const { orders: storedOrders } = storedPurchaseDetails
		// First order status
		const [{ status }] = orders
		if (
			JSON.stringify(orders) === JSON.stringify(storedOrders) ||
			status === OrderStatusWithIcon.READY
		) {
			return true
		}
	}

	removeFromLocalStorage(LOCAL_STORAGE_BANNER_KEY)
	return false
}
