'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { useCarousel } from '../../context/CarouselProvider'
import { Arrow } from './Arrow'

import styles from './Arrows.module.scss'

export function Arrows() {
	const { isTouchable, isLessThanLarge, availableScreenSize } = useResponsive()
	const { isAt } = useCarousel()

	const hideArrows =
		(isTouchable && isLessThanLarge) || (isAt.start && isAt.end)

	return !hideArrows && availableScreenSize ? (
		<div className={styles.arrows}>
			<Arrow direction='BACKWARD' />
			<Arrow direction='FORWARD' />
		</div>
	) : null
}
