'use client'

import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import type { CarouselAction } from '../../types'

import styles from './Action.module.scss'
import text from 'fukku/styles/texts.module.scss'

export const Action = ({
	content,
	href,
	onClick,
}: CarouselAction): React.ReactNode => {
	if (!content || (!href && !onClick)) {
		return null
	}

	const commonClassName = cx(text.bodyM, styles.action)

	return href ? (
		<ClientLink className={commonClassName} href={href} onClick={onClick}>
			{content}
		</ClientLink>
	) : (
		<button className={commonClassName} onClick={onClick}>
			{content}
		</button>
	)
}
