// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconBoxCloseOutline = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='box-close-outline'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M12.5 4 4 7.2v10.7l8.5 3.2 8.5-3.2V7.2zm6.6 3.5-2.6 1L10 6l2.5-1zM12.5 10 5.9 7.5l2.7-1L15.1 9zM5 8.2l7 2.6v9l-7-2.6zM20 14v3.2l-7 2.6v-9l3-1.1V13l1-.4V9.3l3-1.1z' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
