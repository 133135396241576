import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { type CarouselDirection, CarouselVariants } from '../../types'
import { type CarouselContextType, useCarousel } from '../CarouselProvider'

const NUMBER_OF_ITEMS_TO_MOVE_SMALL = 1
const NUMBER_OF_ITEMS_TO_MOVE_LARGE = 2

type UseCarouselMove = {
	move: CarouselContextType['updatePosition']
	moveTo: (direction: CarouselDirection) => void
}

/** @throws {Error} If useCarouselMove is used without CarouselProvider */
export const useCarouselMove = (): UseCarouselMove => {
	const { updatePosition, measures, isAt, variant } = useCarousel()
	const { isSmall } = useResponsive()

	const numberOfItemsToMove =
		variant === CarouselVariants.VARIANT1 && isSmall
			? NUMBER_OF_ITEMS_TO_MOVE_SMALL
			: NUMBER_OF_ITEMS_TO_MOVE_LARGE

	const move: UseCarouselMove['move'] = (
		amount,
		direction,
		behavior = 'smooth'
	) => {
		updatePosition(amount, direction, behavior)
	}

	const moveTo: UseCarouselMove['moveTo'] = (direction) => {
		if (measures) {
			const padding = isAt.start || isAt.end ? measures.padding : 0
			const gap = measures.gap
			const amount =
				padding + (measures.elementWidth + gap) * numberOfItemsToMove
			move(amount, direction)
		}
	}

	return { move, moveTo }
}
