import type { Promotion } from '../../../types/EEC'
import { pushToDatalayer } from '../../../utils/pushToDatalayer/pushToDatalayer'

export const sendSelectPromotionEvent = (promotion: Promotion): void => {
	const payload = {
		event: 'GA4-EEC-selectPromotion',
		event_name: 'select_promotion',
		select_promotion: {
			...promotion,
			genesis: true,
		},
	}
	pushToDatalayer(payload)
}
