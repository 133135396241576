'use client'

import { OrderStatusBanner } from 'order-status-banner/components/OrderStatusBanner/OrderStatusBanner'
import { useGetOrders } from 'order-status-banner/hooks/useGetOrders/useGetOrders'

export const OrderStatusBannerContainer = () => {
	const { purchaseDetails, isLoading, isError } = useGetOrders()

	if (!purchaseDetails || isLoading || isError) {
		return null
	}

	return <OrderStatusBanner purchaseDetails={purchaseDetails} />
}
