'use client'

import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { useHorizontalScroll } from 'fukku/HorizontalScroll/hooks/useHorizontalScroll/useHorizontalScroll'
import { IconDropdownNextSmall } from 'icons/components/IconDropdownNextSmall'
import { IconDropdownPrevSmall } from 'icons/components/IconDropdownPrevSmall'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import type { MasterData } from 'types/masterData'
import { Link } from 'utils/link/Link'

import type { SeoBannerLinkDisplay } from './SeoBanner'

import styles from './SeoBanner.module.scss'

interface SeoBannerListProps {
	seoLinks: SeoBannerLinkDisplay[]
	brandLabel: string
	masterData: MasterData
	previousButtonLabel: string
	nextButtonLabel: string
}

const id = 'seoBanner'

export function SeoBannerList({
	seoLinks,
	brandLabel,
	masterData,
	previousButtonLabel,
	nextButtonLabel,
}: SeoBannerListProps): React.ReactNode {
	const {
		horizontalScrollRef,
		horizontalScrollEvents,
		handleLeftScroll,
		handleRightScroll,
		isAtStart,
		isAtEnd,
	} = useHorizontalScroll()
	const { isLargeOrGreater } = useResponsive()

	return (
		<div className={styles.scrollWrapper}>
			{!isAtStart && (
				<ButtonIconTransparent
					onClick={handleLeftScroll}
					className={styles.buttonPrevious}
					disabled={isAtStart}
					aria-label={previousButtonLabel}
					data-testid={`seoBanner.${id}.previous.button`}
				>
					<IconDropdownPrevSmall className={styles.buttonIcon} />
				</ButtonIconTransparent>
			)}
			<ul
				{...horizontalScrollEvents}
				className={styles.linkList}
				ref={horizontalScrollRef as React.RefObject<HTMLUListElement>}
				data-testid={`seoBanner.${id}.container`}
				id={id}
			>
				{seoLinks.map((seoLink) => {
					const showLink = isLargeOrGreater
						? seoLink.show?.desktop
						: seoLink.show?.mobile
					if (showLink) {
						return (
							<li key={seoLink.label} className={styles.listEl}>
								<Link
									href={seoLink.href}
									masterData={masterData}
									className={styles.link}
									linkProps={{ title: `${seoLink.label} ${brandLabel}` }}
								>
									{seoLink.label}
								</Link>
							</li>
						)
					}
					return null
				})}
			</ul>
			{!isAtEnd && (
				<ButtonIconTransparent
					onClick={handleRightScroll}
					className={styles.buttonNext}
					disabled={isAtEnd}
					aria-label={nextButtonLabel}
					data-testid={`seoBanner.${id}.next.button`}
				>
					<IconDropdownNextSmall className={styles.buttonIcon} />
				</ButtonIconTransparent>
			)}
		</div>
	)
}
