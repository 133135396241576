import { IconBoxCloseOutline } from 'icons/components/IconBoxCloseOutline'
import { IconBoxOpenOutline } from 'icons/components/IconBoxOpenOutline'
import { IconDone } from 'icons/components/IconDone'
import { IconEnvioL } from 'icons/components/IconEnvioL'
import { IconLocationL } from 'icons/components/IconLocationL'
import { IconProductL } from 'icons/components/IconProductL'
import { IconStoreL } from 'icons/components/IconStoreL'
import { type IconProps } from 'icons/types/Icon.types'

import { OrderStatusWithIcon } from '../../types/order/order'

export const getIconByStatus = ({
	status,
	isMultiOrder,
}: {
	status: OrderStatusWithIcon
	isMultiOrder: boolean
}): React.ComponentType<IconProps> => {
	if (isMultiOrder) {
		return IconProductL
	}

	const icons: Record<OrderStatusWithIcon, React.ComponentType<IconProps>> = {
		created: IconBoxOpenOutline,
		in_preparation: IconBoxCloseOutline,
		ready: IconBoxCloseOutline,
		shipped: IconEnvioL,
		on_delivery: IconEnvioL,
		on_international_transit: IconEnvioL,
		delivered: IconDone,
		in_store: IconStoreL,
		in_droppoint: IconLocationL,
	}

	return icons[status]
}
