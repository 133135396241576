// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconBoxOpenOutline = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='box-open-outline'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='m23.2 11.8-2.3-4.6L12.5 4 4.1 7.2l-2.3 4.6 2.2.8v5.3l8.5 3.2 8.5-3.2v-5.3zm-8.5 2.1-1.6-3.1 7.1-2.6 1.6 3.1zM12.5 5l6.6 2.5-6.6 2.5-6.6-2.5zM4.7 8.1l7.1 2.6-1.6 3.1-7.1-2.6zM5 13l5.7 2.2 1.3-2.5v7.1l-7-2.6zm15 4.2-7 2.6v-7.2l1.3 2.5L20 13z' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
