import { cx } from 'utils/cx'

import type { CarouselHeader } from '../../types'
import { Action } from '../Action/Action'
import { Arrows } from '../Arrows/Arrows'

import styles from './Header.module.scss'
import text from 'fukku/styles/texts.module.scss'

export const Header = ({
	title,
	subtitle,
	status,
	action,
	variant,
}: CarouselHeader): React.ReactNode => {
	const showContent = Boolean(title || subtitle || status)

	return (
		<header
			className={cx(
				styles.header,
				styles[`carouselType${variant}`],
				!showContent && styles.withoutContent
			)}
		>
			{showContent && (
				<div className={styles.contentWrapper}>
					{title && (
						<p className={cx(title.textStyle ?? text.uppercaseL, styles.title)}>
							{title.content}
						</p>
					)}
					{subtitle && (
						<p className={cx(text.bodyS, styles.subtitle)}>{subtitle}</p>
					)}
					{status && <p className={cx(text.bodyS, styles.status)}>{status}</p>}
				</div>
			)}
			<div className={styles.actions}>
				<Arrows />
				{action && <Action {...action} />}
			</div>
		</header>
	)
}
