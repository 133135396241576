'use client'

import { useEffect } from 'react'

const WAIT_TIME = 600

export const ScrollToTop = () => {
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		}, WAIT_TIME)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [])

	return null
}
