'use client'

import { type PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { useCarouselMove } from '../../context/useCarouselMove/useCarouselMove'

const DRAG_THRESHOLD = 10

export function Draggable({
	children,
	onChange,
}: PropsWithChildren<{
	onChange?: (isDragging: boolean) => void
}>): JSX.Element {
	const [isDragging, setIsDragging] = useState(false)
	const draggableRef = useRef<HTMLDivElement>(null)
	const updatedStatus = useRef(false)
	const offsetRef = useRef(0)
	const amountRef = useRef(0)

	const { move } = useCarouselMove()
	const { isTouchable } = useResponsive()

	useEffect(() => {
		const handleMouseDown = (e: MouseEvent) => {
			amountRef.current = 0
			setIsDragging(true)
			offsetRef.current = e.clientX
		}

		const handleMouseMove = (e: MouseEvent) => {
			if (!isDragging) {
				return
			}
			amountRef.current = amountRef.current + Math.abs(e.movementX)
			if (amountRef.current < DRAG_THRESHOLD) {
				return
			}

			if (!updatedStatus.current) {
				onChange?.(true)
				updatedStatus.current = true
			}

			const amount = offsetRef.current - e.clientX
			const direction = amount > 0 ? 'FORWARD' : 'BACKWARD'

			move(Math.abs(amount), direction, 'instant')

			offsetRef.current = offsetRef.current - amount
		}

		const handleMouseUp = () => {
			updatedStatus.current = false
			amountRef.current = 0
			onChange?.(false)
			setIsDragging(false)
		}

		if (draggableRef.current && !isTouchable) {
			draggableRef.current.addEventListener('mousedown', handleMouseDown)
			draggableRef.current.addEventListener('mousemove', handleMouseMove)
			draggableRef.current.addEventListener('mouseup', handleMouseUp)
			draggableRef.current.addEventListener('mouseleave', handleMouseUp)
		}
		return () => {
			if (draggableRef.current && !isTouchable) {
				draggableRef.current.removeEventListener('mousedown', handleMouseDown)
				draggableRef.current.removeEventListener('mousemove', handleMouseMove)
				draggableRef.current.removeEventListener('mouseup', handleMouseUp)
				draggableRef.current.removeEventListener('mouseleave', handleMouseUp)
			}
		}
	}, [isDragging])

	return <div ref={draggableRef}>{children}</div>
}
