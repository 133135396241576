import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'

import type { PurchaseDetail } from '../types/purchase/purchase'
import { AnalyticsActionType, OrderType } from '../utils/constants'
import { CommonGenericEvents } from './events'

interface AnalyticEventInterface {
	purchaseDetails: PurchaseDetail
	action: AnalyticsActionType
}

const getPurchaseAnalytics = (purchaseDetails: PurchaseDetail) => {
	const ordersList = purchaseDetails?.orders.map((order) => order.id).join(',')
	const ordersStatus = purchaseDetails?.orders
		.map((order) => order.status)
		.join(',')

	return {
		purchaseID: purchaseDetails.purchaseId,
		orderID: ordersList,
		status: ordersStatus,
	}
}

const getOrderType = (isMultiOrder: boolean, isMultiWarehouse: boolean) => {
	if (isMultiWarehouse) {
		return OrderType.MultiWarehouse
	}

	return isMultiOrder ? OrderType.MultiOrder : OrderType.Unique
}

export const sendAnalytics = ({
	purchaseDetails,
	action,
}: AnalyticEventInterface) => {
	const { isMultiOrder, isMultiWarehouse } = purchaseDetails

	sendGenericEvent(CommonGenericEvents.ORDER_BANNER, {
		action,
		order_type: getOrderType(isMultiOrder, isMultiWarehouse),
		...getPurchaseAnalytics(purchaseDetails),
	})
}
