'use client'

import { HERO_BANNER_SHOP_PLUS_TITLE_TYPE } from 'services/graphql/contentStack/homes/query.type'
import { applyStylesIf, cx } from 'utils/cx'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerShopTitle.module.scss'
import texts from 'fukku/styles/texts.module.scss'

interface HeroBannerShopTitleProps {
	titleType: HERO_BANNER_SHOP_PLUS_TITLE_TYPE | null
	titleKey: string | null
	inverse: boolean
	isLargeOrGreater: boolean
}

const textClassName = (
	titleType: HERO_BANNER_SHOP_PLUS_TITLE_TYPE | null,
	isLargeOrGreater: boolean
) => {
	if (titleType === HERO_BANNER_SHOP_PLUS_TITLE_TYPE.MANGO_SANS) {
		return texts.uppercaseXXL
	}

	return isLargeOrGreater
		? texts.inspirationalTitleXXXL
		: texts.inspirationalTitleXXL
}

export function HeroBannerShopTitle({
	titleType,
	titleKey,
	inverse,
	isLargeOrGreater,
}: HeroBannerShopTitleProps) {
	return (
		<h2
			className={cx(
				animationStyles.opacityFadeIn,
				styles.heroBannerShopTitle,
				applyStylesIf(inverse, styles.inverse),
				textClassName(titleType, isLargeOrGreater)
			)}
		>
			{titleKey}
		</h2>
	)
}
