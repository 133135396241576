import { useEffect } from 'react'
import { Keyboard } from 'types/keyboard'

export function trapFocus(e: KeyboardEvent, element: HTMLElement): void {
	const focusableEls: NodeListOf<HTMLAnchorElement | HTMLButtonElement> =
		element.querySelectorAll(
			'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
		)
	const firstFocusableEl = focusableEls[0]
	const lastFocusableEl = focusableEls[focusableEls.length - 1]
	const isTabPressed = e.key === Keyboard.Tab

	if (!isTabPressed) {
		return
	}

	if (e.shiftKey) {
		/* shift + tab */ if (document.activeElement === firstFocusableEl) {
			e.preventDefault()
			lastFocusableEl.focus()
		}
	} /* tab */ else if (document.activeElement === lastFocusableEl) {
		e.preventDefault()
		firstFocusableEl.focus()
	} else {
		// Do nothing
	}
}

/**
 * Forces the tab focus to be inside the given element.
 * @param element target element where the focus will be trapped
 */
export function useTrapFocus(element: HTMLElement | null): void {
	useEffect(() => {
		if (element) {
			element.addEventListener('keydown', (e) => trapFocus(e, element))
		}

		return () => {
			if (element) {
				element.removeEventListener('keydown', (e) => trapFocus(e, element))
			}
		}
	}, [element])
}
