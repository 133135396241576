import { sendSelectPromotionEvent } from 'analytics/events/eec/selectPromotion/selectPromotion'
import type { Promotion } from 'analytics/types/EEC'
import type { CommBannerProps } from 'fukku/CommsBanners/CommBanner/CommBanner'
import type { ClientTextLinkProps } from 'fukku/TextLink/client'

import {
	type CommBanner,
	type CommCarouselItem,
	type CommCtaShop,
	type SectionType,
	TEXT_COLOR,
} from '../../../../Home.type'
import { promotionFactory } from '../../../../analytics/promotionFactory'
import type { CurrentVisibleComm } from './CommsCarouselClient'

export interface CommsWithLinksAndPromotionsProps {
	allCommsWithLinks: CommBannerProps[]
	commsPromotions: { id: string; promotions: Promotion[] }[]
}

interface CommCtasProps {
	comm: CommBanner
	allCommsBrandCtas: CommCtaShop[]
	allCommsSectionCtas: CommCtaShop[]
	sectionType: SectionType.CommsCarousel
	slot: string
}

interface CommCtasOut {
	newCommLinks: ClientTextLinkProps[]
	commPromotions: Promotion[]
}

interface CommsToCommsWithLinksAndPromotionsProps {
	comms: CommBanner[]
	allCommsBrandCtas: CommCtaShop[]
	allCommsSectionCtas: CommCtaShop[]
	infoIconLabel: string
	closeButtonLabel: string
	sectionType: SectionType.CommsCarousel
	slot: string
	handleOnShowComm: (currentVisibleComm: CurrentVisibleComm) => void
}

const isCommActive = (promoStartDate?: string, promoEndDate?: string) => {
	if (!promoEndDate) {
		return true
	}
	const date = new Date()
	const startDate = promoStartDate ? new Date(promoStartDate) : date
	const endDate = new Date(promoEndDate)

	return date >= startDate && date <= endDate
}

export function getCommCtasAndPromotions({
	comm,
	allCommsBrandCtas,
	allCommsSectionCtas,
	sectionType,
	slot,
}: CommCtasProps): CommCtasOut {
	const commPromotions: Promotion[] = []
	const commSection: CommCarouselItem = {
		uid: comm.uid,
		promotionName: comm.promotionName ?? '',
		sectionType,
	}
	const ctaToPromotion = promotionFactory(commSection, slot)
	const newCommLinks: ClientTextLinkProps[] = []

	comm.sectionCtas.forEach((sectionCta) => {
		const commCta = allCommsSectionCtas.find(
			({ ctaId }) =>
				ctaId === sectionCta.sectionId || ctaId === sectionCta.featureId
		)
		if (commCta) {
			const promotion = ctaToPromotion(commCta.ctaId)
			commPromotions.push(promotion)
			newCommLinks.push({
				children: commCta.text,
				href: commCta.href,
				withLegacyLocale: commCta.withLegacyLocale,
				onClick: () => sendSelectPromotionEvent(promotion),
			})
		}
	})

	if (comm.lineCtas) {
		allCommsBrandCtas.forEach((commCta) => {
			const promotion = ctaToPromotion(commCta.ctaId)
			commPromotions.push(promotion)
			newCommLinks.push({
				children: commCta.text,
				href: commCta.href,
				onClick: () => sendSelectPromotionEvent(promotion),
				isLocalizedPath: true,
			})
		})
	}

	return {
		newCommLinks,
		commPromotions,
	}
}

export function commsToCommsWithLinksAndPromotions({
	comms,
	allCommsBrandCtas,
	allCommsSectionCtas,
	infoIconLabel,
	closeButtonLabel,
	sectionType,
	slot,
	handleOnShowComm,
}: CommsToCommsWithLinksAndPromotionsProps): CommsWithLinksAndPromotionsProps {
	return comms.reduce(
		(
			{ allCommsWithLinks, commsPromotions }: CommsWithLinksAndPromotionsProps,
			comm
		) => {
			const { newCommLinks, commPromotions } = getCommCtasAndPromotions({
				comm,
				allCommsBrandCtas,
				allCommsSectionCtas,
				sectionType,
				slot,
			})
			const {
				uid,
				type,
				title,
				discount,
				subtitle,
				subtitlesGroup,
				startDate,
				endDate,
				maxDays,
				countdown,
				infoText,
				closable,
				backgroundColorType,
				customBackgroundColor,
				contentColor,
				showInfo,
			} = comm
			const newComm: CommBannerProps = {
				type,
				title,
				discount,
				subtitle,
				subtitlesGroup,
				maxDays,
				countdown,
				closable,
				infoIconLabel,
				closeButtonLabel,
				id: uid,
				infoText: showInfo && infoText ? infoText : '',
				inverse: contentColor === TEXT_COLOR.White,
				bgColorType: backgroundColorType,
				bgColorCode: customBackgroundColor,
				links: newCommLinks,
				onShow: handleOnShowComm,
			}
			if (isCommActive(startDate, endDate)) {
				allCommsWithLinks.push(newComm)
				commsPromotions.push({ id: newComm.id, promotions: commPromotions })
			}
			return { allCommsWithLinks, commsPromotions }
		},
		{ allCommsWithLinks: [], commsPromotions: [] }
	)
}
