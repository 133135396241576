// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconLocationL = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='location-l'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M12 2C9.355 2 6.875 3.542 5.682 5.929c-1.295 2.589-.724 5.925 1.511 8.909.072.105 4.391 6.585 4.391 6.585l.416.624.416-.624 4.377-6.567c2.25-3.002 2.82-6.338 1.525-8.927C17.125 3.542 14.645 2 12 2m3.999 12.25c-.016.02-.019.022-3.999 5.994-3.98-5.972-3.983-5.974-3.993-5.987-2.017-2.693-2.552-5.639-1.43-7.881C7.602 4.326 9.73 3 12 3s4.398 1.325 5.423 3.376c1.122 2.242.587 5.188-1.424 7.874M12 5C9.794 5 8 6.794 8 9s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4m0 7c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
