'use client'

import { sendSelectPromotionEvent } from 'analytics/events/eec/selectPromotion/selectPromotion'
import { ButtonLink } from 'fukku/Button/ButtonLink'
import {
	type CtaShop,
	HeroBannerShopType,
	type Video,
} from 'landings/Home/Home.type'
import { useEffect, useRef, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { applyStylesIf, cx } from 'utils/cx'

import { HeroBannerVideo } from '../../../components/HeroBannerVideo/HeroBannerVideo'
import { HeroBannerShopSalesText } from '../HeroBannerShopSalesText/HeroBannerShopSalesText'

import animationStyles from '../../../../MotionAnimations.module.scss'
import ctaStyles from '../../../../ctas/SingleCtaShop.module.scss'
import styles from './HeroBannerShopSalesContent.module.scss'
import texts from 'fukku/styles/texts.module.scss'

interface HeroBannerShopSalesContentProps {
	type: HeroBannerShopType
	video: {
		videoPortrait?: Video
		videoLandscape?: Video
		videoPlaceholder?: {
			url: string
		} | null
	} | null
	videoButtonTitle: {
		play: string
		pause: string
	}
	titleKey: string
	inverse: boolean
	ctas: CtaShop[]
	subtitleTopKey?: string
	discount?: string
	subtitleBottomGroup?: {
		titleKey: string
		subtitleKey: string
	}[]
	subtitleBottomKey?: string
}

const BUTTON_TEXT_CLASSNAME = 'buttonTextElement'

export const HeroBannerShopSalesContent = ({
	type,
	video,
	videoButtonTitle,
	subtitleTopKey,
	titleKey,
	discount,
	subtitleBottomGroup,
	subtitleBottomKey,
	inverse,
	ctas,
}: HeroBannerShopSalesContentProps) => {
	const isVideo = type === HeroBannerShopType.video
	const ctaWrapperRef = useRef<HTMLDivElement>(null)
	const ctaWrapper = ctaWrapperRef.current

	const { isLargeOrGreater, isSmall } = useResponsive()

	const [isFullWidthCta, setIsFullWidthCta] = useState(false)
	const [canRenderText, setCanRenderText] = useState(!isVideo)

	useEffect(() => {
		const handleCtasResize = () => {
			const elements = Array.from(
				ctaWrapper?.querySelectorAll(`.${BUTTON_TEXT_CLASSNAME}`) ?? []
			)
			const someOverflowElement =
				elements.length > 1 &&
				elements.some((element) => element.scrollWidth > element.clientWidth)
			const isFullWidth = isSmall && elements.length > 1 && someOverflowElement

			if (!isSmall) {
				setIsFullWidthCta(false)
			}

			if (isFullWidth) {
				setIsFullWidthCta(true)
			}
		}
		handleCtasResize()
		window?.addEventListener('resize', handleCtasResize)

		return () => {
			window?.removeEventListener('resize', handleCtasResize)
		}
	}, [isSmall, ctaWrapper])

	const handleVideoCanPlay = () => {
		setCanRenderText(true)
	}

	return (
		<>
			{isVideo && video ? (
				<HeroBannerVideo
					video={video}
					inverse={inverse}
					videoButtonTitle={videoButtonTitle}
					videoButtonClassName={styles.videoButton}
					onVideoCanPlay={handleVideoCanPlay}
				/>
			) : null}
			{canRenderText ? (
				<div
					className={cx(styles.contentWrapper, animationStyles.opacityFadeIn)}
				>
					<HeroBannerShopSalesText
						subtitleTopKey={subtitleTopKey}
						titleKey={titleKey}
						discount={discount}
						subtitleBottomGroup={subtitleBottomGroup}
						subtitleBottomKey={subtitleBottomKey}
						inverse={inverse}
						isLargeOrGreater={isLargeOrGreater}
					/>
					{ctas.length === 1 ? (
						<div className={cx(ctaStyles.singleCta, texts.bodyMRegular)}>
							{ctas[0].text}
						</div>
					) : (
						<div className={cx(styles.ctaWrapper)} ref={ctaWrapperRef}>
							{ctas.map(({ ctaId, text, href, linkProps, promotion }) => (
								<ButtonLink
									key={ctaId}
									linkAttributes={{ ...linkProps, href }}
									className={cx(
										styles.ctaButton,
										styles.halfWidthButton,
										applyStylesIf(isFullWidthCta, styles.fullWidthCta)
									)}
									onClick={() => sendSelectPromotionEvent(promotion)}
								>
									<span
										className={cx(
											styles.buttonTextContainer,
											BUTTON_TEXT_CLASSNAME
										)}
									>
										{text}
									</span>
								</ButtonLink>
							))}
						</div>
					)}
				</div>
			) : null}
		</>
	)
}
