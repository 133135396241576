import { Channel } from 'types/masterData'

export enum ContentTypeResponse {
	AbTest = 'a_b_test',
	HeroBanner = 'hero_banner',
	HeroBannerSecondaryLines = 'hero_banner_secondary_lines',
	HeroBannerPrimaryLines = 'hero_banner_primary_lines',
	FamiliesBanner = 'families_banner',
	FamilyBannerShop = 'family_banner_shop',
	FamiliesCarousel = 'families_carousel',
	FamilyBanner = 'family_banner',
	FamiliesDirectAccess = 'families_direct_access',
	FamilyDirectAccess = 'family_direct_access',
	CommsBanner = 'comms_banner',
	CommBanner = 'comm_banner',
	PromotionContent = 'promotion_content',
	CommsBannerCarousel = 'comms_banner_carousel',
	LinesDirectAccess = 'lines_direct_access',
	CrossSellingCarousel = 'cross_selling_carousel',
	HeroBannerShop = 'hero_banner_shop',
	HeroBannerShopMultibrand = 'hero_banner_shop_multibrand',
	HeroBannerShopSales = 'hero_banner_sales_shop',
	SeoBanner = 'seo_banner',
}

export enum ImageContentTypes {
	jpeg = 'image/jpeg',
	svg = 'image/svg+xml',
}

export enum HeroBannerSectionResponseType {
	ctas = 'ctas',
	primaryLines = 'primary_lines',
	secondaryLines = 'secondary_lines',
	promo = 'promo',
}

interface Cta {
	href: string
	title: string
	ctaAnalyticsId: string
	inverse: boolean
	legacy: boolean
	noFollow: boolean
	target: string
}

interface SimpleCta {
	labelKey: string
}

interface SectionSimpleCta {
	labelKey: string
	sectionId: string
}

export type SectionCtasResponse = {
	labelKey: string
	sectionId: string | null
	brandIdSelector: {
		brandId: string
	}
	featureIdSelector?: {
		featureId: FEATURE_ID | null
	}
}[]

export interface ContentAbTestUidResponse {
	system: {
		contentType: ContentTypeResponse.AbTest
		uid: string
		tags: string[]
	}
}

export interface ContentAbTestResponse {
	testKey: string
	variants: {
		edges: SectionsNodeResponse[]
	}
	system: {
		contentType: ContentTypeResponse.AbTest
		uid: string
		tags: string[]
	}
}

export interface ContentHeroBannerPrimaryLinesResponse {
	catalogId: string
	promotionName: string
	image: {
		horizontal: number
		vertical: number
		file: {
			edges: {
				node: {
					url: string
					dimension: {
						height: number
						width: number
					}
				}
			}[]
		}
	}
	system: {
		contentType:
			| ContentTypeResponse.HeroBannerPrimaryLines
			| ContentTypeResponse.HeroBanner
		uid: string
		tags: string[]
	}
}

export interface ContentHeroBannerSecondaryLinesResponse {
	title: string
	subTitle: string
	simpleCta: SimpleCta
	catalogId: string
	promotionName: string
	image: {
		horizontal: number
		vertical: number
		file: {
			edges: {
				node: {
					url: string
					dimension: {
						height: number
						width: number
					}
				}
			}[]
		}
	}
	system: {
		contentType:
			| ContentTypeResponse.HeroBannerSecondaryLines
			| ContentTypeResponse.HeroBanner
		uid: string
		tags: string[]
	}
}

export enum ContentHeroBannerPromoType {
	general = 'general',
	progressive = 'progressive',
	family = 'family',
}

export enum ContentHeroBannerAvailableColors {
	red = 'red',
	black = 'black',
	lightBlue = 'light-blue',
	darkBlue = 'dark-blue',
	lightGreen = 'light-green',
	darkGreen = 'dark-green',
	orange = 'orange',
	lightBurgundy = 'light-burgundy',
	darkBurgundy = 'dark-burgundy',
}

interface PromoTitles {
	title: string
	discount: string
}

interface PromoSubtitles {
	condition: string
	onlineExclusive: boolean
	dueDate: string
	promoCode: string
}

interface ProgressivePromoTitles {
	title1: string
	strategy1: string
	title2: string
	strategy2: string
}

interface FamilyPromoTitles {
	title: string
	subtitle: string
}

interface ContentHeroBannerPromo {
	promoType: ContentHeroBannerPromoType
	countdown: string
	promoBackgroundColor: ContentHeroBannerAvailableColors
	promoTitles: PromoTitles
	progressivePromoTitles: ProgressivePromoTitles
	familyPromoTitles: FamilyPromoTitles
	promoSubtitles: PromoSubtitles
	sectionId: string
	promoLegalInfo: string
}

export interface ContentHeroBannerResponse {
	/* Common field. */
	type: HeroBannerSectionResponseType
	/* Analytics promotion name. Common field. */
	promotionName: string
	/* Common field. */
	image: {
		horizontal: number
		vertical: number
		file: {
			edges: {
				node: {
					url: string
					dimension: {
						height: number
						width: number
					}
				}
			}[]
		}
	}
	/* CTAs hero banner field */
	linesCtas: boolean
	/* CTAs hero banner field */
	ctas: Cta[]
	/* Secondary lines hero banner field */
	title: string
	/* Secondary lines hero banner field */
	subTitle: string
	/* Secondary lines hero banner field */
	simpleCta: SimpleCta
	/* Secondary and primary line hero banner field */
	catalogId: string
	/* Common metadata */
	promo: ContentHeroBannerPromo
	system: {
		contentType: ContentTypeResponse.HeroBanner
		uid: string
		tags: string[]
	}
}

export interface ContentFamilyBannerResponse {
	promotionName: string
	text: string
	catalogId: string
	sectionId: string
	image: PointOfInterestImageField
	system: {
		contentType: ContentTypeResponse.FamilyBanner
		uid: string
		tags: string[]
	}
}

export interface ContentFamilyBannerShopResponse {
	contentColor: TEXT_COLOR
	promotionName: string
	familySections: {
		titleKey: string
		cta: {
			sectionId: string
			labelKey: string
		}
		image: PointOfInterestImageField
	}[]
	system: {
		contentType: ContentTypeResponse.FamilyBannerShop
		uid: string
		tags: string[]
	}
}

export interface ContentFamiliesBannerResponse {
	families: {
		edges: {
			node: ContentFamilyBannerResponse
		}[]
	}
	system: {
		contentType: ContentTypeResponse.FamiliesBanner
		uid: string
		tags: string[]
	}
}

export interface ContentFamiliesCarouselResponse {
	title: string
	showLink: boolean
	sectionId: string
	families: {
		edges: {
			node: ContentFamilyBannerResponse
		}[]
	}
	system: {
		contentType: ContentTypeResponse.FamiliesCarousel
		uid: string
		tags: string[]
	}
}

export interface ContentFamilyDirectAccessResponse {
	promotionName: string
	textLabel: string
	sectionId: string
	image: PointOfInterestImageField
	system: {
		contentType: ContentTypeResponse.FamilyDirectAccess
		uid: string
		tags: string[]
	}
}

export interface ContentFamiliesDirectAccessResponse {
	familiesDirectAccess: {
		edges: {
			node: ContentFamilyDirectAccessResponse
		}[]
	}
	system: {
		contentType: ContentTypeResponse.FamiliesDirectAccess
		uid: string
		tags: string[]
	}
}

export enum COMM_BANNER_TYPE {
	PROMO = 'promo',
	INFO = 'info',
}

export enum COMM_BANNER_BG_COLOR_TYPE {
	WHITE = 'white',
	BLACK = 'black',
	RED = 'red',
	LIGHT_BLUE = 'lightBlue',
	DARK_BLUE = 'darkBlue',
	LIGHT_GREEN = 'lightGreen',
	DARK_GREEN = 'darkGreen',
	ORANGE = 'orange',
	LIGHT_BURGUNDY = 'lightBurgundy',
	DARK_BURGUNDY = 'darkBurgundy',
	CUSTOM = 'custom',
}

export enum TEXT_COLOR {
	White = 'white',
	Black = 'black',
}

export enum FEATURE_ID {
	MangoLikesYou = 'mango-likes-you',
	Discover = 'discover',
	Help = 'help',
}

export interface ContentCommsBannerResponse {
	title: string
	subtitle: string
	promotionName: string
	type: string
	linesCtas: boolean
	sectionCtas: SectionSimpleCta[]
	showInfo: boolean
	infoText: string
	system: {
		contentType: ContentTypeResponse.CommsBanner
		uid: string
		tags: string[]
	}
}

export interface PromotionContentResponse {
	promotionTitle: string
	discount: string
	subtitle: string
	subtitlesGroup: {
		titleKey: string
		subtitleKey: string
	}[]
	lineCtas: boolean
	sectionCtas: SectionCtasResponse
	infoText: string
	startDate: string
	endDate: string
	promotionName: string
	system: {
		contentType: ContentTypeResponse.PromotionContent
		uid: string
		tags: string[]
	}
}

export interface ContentCommBannerResponse {
	type: COMM_BANNER_TYPE
	promotion: {
		edges: {
			node: PromotionContentResponse
		}[]
	}
	infoTitle: string
	infoSubtitle: string
	infoLineCtas: boolean
	infoPromotionName: string
	infoSectionCtas: SectionCtasResponse
	closable: boolean
	contentColor: TEXT_COLOR
	backgroundColorType: string
	customBackgroundColor: string
	showInfo: boolean
	showCountdown: boolean
	timeBeforeEndToShowCountdown: number
	system: {
		contentType: ContentTypeResponse.CommBanner
		uid: string
		tags: string[]
	}
}

export interface ContentCommsCarouselResponse {
	comms: {
		edges: {
			node: ContentCommBannerResponse
		}[]
	}
	system: {
		contentType: ContentTypeResponse.CommsBannerCarousel
		uid: string
		tags: string[]
	}
}

export interface ContentLinesDirectAccessResponse {
	promotionName: string
	system: {
		contentType: ContentTypeResponse.LinesDirectAccess
		uid: string
		tags: string[]
	}
}

export enum HeroBannerShopType {
	image = 'image',
	video = 'video',
}

export interface ImageNode {
	url: string
	description: string
	contentType: ImageContentTypes
	dimension: {
		width: number
		height: number
	}
}

export interface ImageFile {
	edges: {
		node: ImageNode
	}[]
}

export interface File {
	edges: {
		node: {
			url: string
			contentType: string
		}
	}[]
}

export interface HeroBannerShopVideo {
	videoPortrait: File
	videoLandscape: File
	videoPlaceholder: File
}

export interface PointOfInterestImageField {
	horizontal: number
	vertical: number
	file: ImageFile
}

export enum HERO_BANNER_SHOP_PLUS_TITLE_TYPE {
	INSPIRATIONAL = 'inspirational',
	MANGO_SANS = 'mangoSans',
	IMAGE = 'image',
}

export interface ContentHeroBannerShopResponse {
	titleType: HERO_BANNER_SHOP_PLUS_TITLE_TYPE
	titleKey: string | null
	titleImage: {
		svgImage: ImageFile
		mobileWidth: number
		desktopWidth: number
	} | null
	extraMobileTitleImage: boolean | null
	mobileTitleImage: {
		svgImage: ImageFile
		mobileWidth: number
	} | null
	type: HeroBannerShopType
	promotionName: string
	contentColor: TEXT_COLOR
	image: PointOfInterestImageField
	extraImage: boolean
	alternativeImage: PointOfInterestImageField | null
	video: HeroBannerShopVideo
	ctas: SectionCtasResponse
	system: {
		contentType: ContentTypeResponse.HeroBannerShop
		uid: string
		tags: string[]
	}
}

export interface ContentHeroBannerShopMultibrandResponse {
	titleKey: string
	contentColor: TEXT_COLOR
	promotionName: string
	defaultImage: PointOfInterestImageField
	extraImage: boolean
	alternativeImage: PointOfInterestImageField | null
	brandCtas: {
		brandId: string
		image: PointOfInterestImageField
	}[]
	sectionCtas: {
		labelKey: string
		sectionId: string
		image: PointOfInterestImageField
		featureIdSelector?: {
			featureId: FEATURE_ID | null
		}
	}[]
	system: {
		contentType: ContentTypeResponse.HeroBannerShopMultibrand
		uid: string
		tags: string[]
	}
}

export interface ContentHeroBannerShopSalesResponse {
	promotion: {
		edges: {
			node: PromotionContentResponse
		}[]
	}
	subtitleTopKey: string
	subtitleBottomGroup: {
		titleKey: string
		subtitleKey: string
	}[]
	contentColor: TEXT_COLOR
	showInfo: boolean
	type: HeroBannerShopType
	image: PointOfInterestImageField
	extraImage: boolean
	alternativeImage: PointOfInterestImageField | null
	video: HeroBannerShopVideo
	system: {
		contentType: ContentTypeResponse.HeroBannerShopSales
		uid: string
		tags: string[]
	}
}

interface LinkLabel {
	labelId: string
}

export interface SeoLink extends LinkLabel {
	url: string
	hideIn?: {
		desktop: boolean
		mobile: boolean
		apps: boolean
	}
}

export interface SubbrandLinks extends LinkLabel {
	families: SeoLink[]
}

export interface SeoBannerDataResponse {
	id: string
	items: SubbrandLinks[]
}

export interface SeoBannerFailedResponse {
	origin: string
	message: string
}

export interface ContentSeoBannerResponse {
	seoBannerLinks: SeoBannerDataResponse[]
	system: {
		contentType: ContentTypeResponse.SeoBanner
		uid: string
		tags: string[]
	}
}

export type SectionsResponse =
	| ContentAbTestUidResponse
	| ContentHeroBannerResponse
	| ContentHeroBannerPrimaryLinesResponse
	| ContentHeroBannerSecondaryLinesResponse
	| ContentFamiliesBannerResponse
	| ContentFamiliesCarouselResponse
	| ContentFamiliesDirectAccessResponse
	| ContentCommsBannerResponse
	| ContentCommsCarouselResponse
	| ContentLinesDirectAccessResponse
	| ContentCrossSellingCarouselResponse
	| ContentHeroBannerShopResponse
	| ContentHeroBannerShopMultibrandResponse
	| ContentFamilyBannerShopResponse
	| ContentHeroBannerShopSalesResponse
	| ContentSeoBannerResponse

export type HeroBannerSectionsResponse =
	| ContentHeroBannerPrimaryLinesResponse
	| ContentHeroBannerSecondaryLinesResponse

export type SectionsNodeResponse = {
	node: SectionsResponse
}

export interface ContentHomeResponseItem {
	priority?: number | null
	sections: {
		edges: SectionsNodeResponse[]
	}
	seo: {
		description: string
		title: string
	}
}

export interface ContentHomeResponse<T extends Channel> {
	all_home_outlet?: T extends Channel.Outlet
		? {
				items: ContentHomeResponseItem[]
			}
		: undefined
	all_home_shop?: T extends Channel.Shop
		? {
				items: ContentHomeResponseItem[]
			}
		: undefined
}

export interface CrossSellingServiceResponse {
	productId: string
	colorId: string
	lookId: string
}

export interface ContentCrossSellingCarouselResponse {
	crossSellingType: string
	imageType: string
	promotionName: string
	bestSellersBrand: string
	catalog: {
		catalogId: string
		title: string
	}
	customProducts: {
		title: string
		products: CrossSellingServiceResponse[]
	}
	system: {
		contentType: ContentTypeResponse.CrossSellingCarousel
		uid: string
		tags: string[]
	}
}
