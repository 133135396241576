import { useCallback } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import type { CarouselMeasures } from '../../types'

type DragObjectRef = React.RefObject<HTMLUListElement>
type CarouselDrag = (carousel: DragObjectRef, isDragging: boolean) => void

const DELAY_TO_UPDATE_POSITION = 300
const MIDDLE = 0.5

export function useCarouselDrag(measures: CarouselMeasures | null) {
	const { isTouchable } = useResponsive()

	const setStylesDragging = (carousel: DragObjectRef) => {
		if (carousel.current) {
			carousel.current.style.scrollSnapType = 'none'
		}
	}

	const setStylesMoving = (carousel: DragObjectRef) => {
		if (carousel.current) {
			scrollToNearElement(carousel)
			setTimeout(() => {
				if (!carousel.current) {
					return
				}
				carousel.current.style.scrollSnapType = 'x mandatory'
			}, DELAY_TO_UPDATE_POSITION)
		}
	}

	const scrollToNearElement = (carousel: DragObjectRef) => {
		if (carousel.current && measures) {
			const elementsHidden =
				(carousel.current.scrollLeft - measures.padding) /
				(measures.elementWidth - measures.gap)

			const decimal = elementsHidden - Math.floor(elementsHidden)
			const numElementsHidden = Math.floor(elementsHidden)
			const elementWidthAndGap = measures.elementWidth + measures.gap
			const nextElement = decimal > MIDDLE ? elementWidthAndGap : 0
			const newPosition = elementWidthAndGap * numElementsHidden + nextElement

			carousel.current.scrollTo({
				left: newPosition,
				behavior: 'smooth',
			})
		}
	}

	return useCallback<CarouselDrag>(
		(carousel, isDragging) => {
			if (!isTouchable && measures) {
				isDragging ? setStylesDragging(carousel) : setStylesMoving(carousel)
			}
		},
		[measures, isTouchable]
	)
}
