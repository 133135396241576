import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import type { RefObject } from 'react'

const DEFAULT_HEADER_HEIGHT = 16

export const useHeaderHeight = (): {
	headerHeight: number
	headerRef: RefObject<HTMLHeadElement>
} => {
	const [headerHeight, setHeaderHeight] = useState(DEFAULT_HEADER_HEIGHT)
	const headerRef = useRef<HTMLHeadElement | null>(null)

	useEffect(() => {
		headerRef.current = document.getElementById('header')

		const updateHeaderHeight = () => {
			if (headerRef.current) {
				const { height } = headerRef.current.getBoundingClientRect()
				setHeaderHeight(height)
			}
		}

		updateHeaderHeight()

		const resizeObserver = new ResizeObserver(updateHeaderHeight)

		if (headerRef.current) {
			resizeObserver.observe(headerRef.current)
		}

		return () => {
			resizeObserver.disconnect()
		}
	}, [])

	useLayoutEffect(() => {
		document.body.style.setProperty('--header-height', `${headerHeight}px`)
	}, [headerHeight])

	return {
		headerHeight,
		headerRef,
	}
}
