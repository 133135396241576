'use client'

import { useHeaderHeight } from 'hooks/useHeaderHeight/useHeaderHeight'
import { useRef } from 'react'
import { cx } from 'utils/cx'

import { BannerWrapper } from '../../../../BannerWrapper/BannerWrapper'

import styles from './HeroBannerFullHeightWrapper.module.scss'

interface HeroBannerFullHeightWrapperProps {
	className?: string
	isPrioritySlot?: boolean
	children: React.ReactNode
}

export function HeroBannerFullHeightWrapper({
	className,
	children,
	isPrioritySlot,
}: HeroBannerFullHeightWrapperProps) {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const { headerHeight } = useHeaderHeight()
	const headerHeightStyle = {
		'--header-height': `${headerHeight}px`,
	} as React.CSSProperties

	return (
		<BannerWrapper
			ref={wrapperRef}
			style={headerHeightStyle}
			className={cx(styles.heroBannerFullHeightWrapper, className)}
			priority={isPrioritySlot}
		>
			{children}
		</BannerWrapper>
	)
}

export default HeroBannerFullHeightWrapper
