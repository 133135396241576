'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import {
	type HeroBannerPromo,
	HeroBannerPromoType,
} from '../../../../Home.type'

import styles from './HeroBannerPromoFamily.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface HeroBannerPromoFamilyProps {
	section: HeroBannerPromo
}

export function HeroBannerPromoFamily({ section }: HeroBannerPromoFamilyProps) {
	const { isLargeOrGreater } = useResponsive()
	if (section.promoType !== HeroBannerPromoType.family) {
		return null
	}

	const {
		familyPromoTitles: { title, subtitle },
	} = section

	return (
		<div className={styles.titles}>
			<h2 className={styles.titlesWrapper}>
				<div className={text.inspirationalTitleL}>{title}</div>
				<div className={isLargeOrGreater ? text.titleXXL : text.titleXL}>
					{subtitle}
				</div>
			</h2>
		</div>
	)
}
