'use client'

import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { Alert } from 'order-status-banner/types/alert/alert'

import styles from './OrderStatusWarning.module.scss'

interface OrderStatusWarningProps {
	alert: Alert
}

export const OrderStatusWarning = ({ alert }: OrderStatusWarningProps) => {
	const { t } = useLabels()

	return (
		<span className={styles.warning}>
			<ClientLabel>{t(`myPurchases.online.alert_${alert}.title`)}</ClientLabel>
		</span>
	)
}
