import { useFetch } from 'hooks/useFetch/useFetch'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'
import useSWRImmutable from 'swr/immutable'
import { env } from 'utils/envManager'
import { isBot } from 'utils/isBot/IsBot'
import { url } from 'utils/url'

import type { FetchedOrder } from '../../types/purchase/purchase'
import { normalize } from './normalize'

export const useGetOrders = () => {
	const { channel } = useMasterData()
	const { fetcher } = useFetch()
	const { logged } = useSession()

	const { data, isLoading, error } = useSWRImmutable<FetchedOrder | null>(
		channel && logged && !isBot()
			? {
					url: url(
						`${env.NEXT_PUBLIC_SITE_BASE_URL}/ws-my-purchases/${channel}/purchases/banner`
					),
				}
			: null,
		fetcher,
		{}
	)

	return {
		purchaseDetails: data && normalize(data),
		isError: !!error,
		isLoading,
	}
}
