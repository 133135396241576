'use client'

import { Tooltip } from 'fukku/Tooltip'
import { IconInfoL } from 'icons/components/IconInfoL'
import { useRef } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import {
	type HeroBannerPromo,
	HeroBannerPromoType,
} from '../../../../Home.type'
import type { SectionDataType } from '../../../../types/sectionData'
import { useActiveLink } from '../../ctas/hooks/onActiveLink'
import {
	DEVICE_TYPE,
	HeroBannerImage,
} from '../components/HeroBannerImage/HeroBannerImage'
import { HeroBannerPromoCountdown } from './HeroBannerPromoCountdown'
import { HeroBannerPromoFamily } from './HeroBannerPromoFamily'
import { HeroBannerPromoGeneral } from './HeroBannerPromoGeneral'
import { HeroBannerPromoProgressive } from './HeroBannerPromoProgressive'

import stylesBGColor from '../../PromotionsBackgroundColors.module.scss'
import ctaStyles from '../../ctas/SingleCtaOutlet.module.scss'
import styles from './HeroBannerPromo.module.scss'
import text from 'fukku/styles/texts.module.scss'

type HeroBannerPromoContentProps = {
	section: HeroBannerPromo
	sections: SectionDataType[]
	isMobile: boolean
	isPrioritySlot: boolean
	onlineExclusiveLabel: string
	buyNowButtonLabel: string
}

type HeroBannerPromoTypeProps = {
	section: HeroBannerPromo
}

type HeroBannerPromoSubcomponent =
	| typeof HeroBannerPromoGeneral
	| typeof HeroBannerPromoProgressive
	| typeof HeroBannerPromoFamily

const ASPECT_RATIO_PORTRAIT_WIDTH = 5
const ASPECT_RATIO_PORTRAIT_HEIGHT = 7

const ASPECT_RATIO_PORTRAIT =
	ASPECT_RATIO_PORTRAIT_WIDTH / ASPECT_RATIO_PORTRAIT_HEIGHT

function HeroBannerPromoTypeComponent({ section }: HeroBannerPromoTypeProps) {
	const promoMap: Record<HeroBannerPromoType, HeroBannerPromoSubcomponent> = {
		[HeroBannerPromoType.general]: HeroBannerPromoGeneral,
		[HeroBannerPromoType.progressive]: HeroBannerPromoProgressive,
		[HeroBannerPromoType.family]: HeroBannerPromoFamily,
	}

	const PromoComponent = promoMap[section.promoType]

	return <PromoComponent section={section} />
}

export function HeroBannerPromoContent({
	section,
	sections,
	isMobile,
	isPrioritySlot,
	onlineExclusiveLabel,
	buyNowButtonLabel,
}: HeroBannerPromoContentProps): React.ReactNode {
	const {
		image,
		countdown,
		promoBackgroundColor,
		promoLegalInfo,
		promoSubtitles: { condition, onlineExclusive, dueDate, promoCode },
	} = section

	const ref = useRef<HTMLAnchorElement>(null)
	const activeLink = useActiveLink(ref)

	return (
		<a href={sections[0].url} className={styles.bannerWrapper} ref={ref}>
			<div className={styles.layout}>
				<HeroBannerImage
					className={styles.image}
					image={image}
					isAlwaysPortrait
					priority={isPrioritySlot}
					deviceHeightCalc={{
						[DEVICE_TYPE.Mobile]: (width) => width / ASPECT_RATIO_PORTRAIT,
					}}
				/>
				<div
					className={cx(styles.solidColor, stylesBGColor[promoBackgroundColor])}
				/>
			</div>
			<div className={styles.layout}>
				<div className={styles.promoGeneral}>
					<div className={styles.promo}>
						<div className={cx(styles.counter, text.titleXL)}>
							<HeroBannerPromoCountdown date={countdown} />
						</div>
						<HeroBannerPromoTypeComponent section={section} />
						<h3 className={cx(styles.subtitles, text.titleM)}>
							<div>
								{[condition, onlineExclusive && onlineExclusiveLabel]
									.filter(Boolean)
									.join(' | ')}
							</div>
							<div>{[dueDate, promoCode].filter(Boolean).join(' | ')}</div>
						</h3>
					</div>
					<div className={styles.cta}>
						<div
							className={cx(
								ctaStyles.singleCta,
								applyStylesIf(activeLink, ctaStyles.active)
							)}
						>
							{buyNowButtonLabel}
						</div>
					</div>
					{promoLegalInfo ? (
						<div className={styles.legalInfoTooltipButton}>
							<Tooltip
								position={isMobile ? 'bottom' : 'left'}
								content={<p>{promoLegalInfo}</p>}
							>
								<span>
									<IconInfoL color='content-inverse'></IconInfoL>
								</span>
							</Tooltip>
						</div>
					) : null}
				</div>
			</div>
		</a>
	)
}
