import { Children, type ForwardedRef, cloneElement, forwardRef } from 'react'
import { cx } from 'utils/cx'

import type { CarouselItems } from '../../types'

import styles from './Items.module.scss'

export const Items = forwardRef<HTMLUListElement, CarouselItems>(
	(
		{ onScroll, children, variant, isDragging }: CarouselItems,
		innerRef: ForwardedRef<HTMLUListElement>
	) => {
		return (
			<ul
				onScroll={onScroll}
				ref={innerRef}
				className={cx(styles.items, styles[`carouselType${variant}`])}
			>
				{Children.map(children, (child, index) => {
					const childKey = (child as React.ReactElement).key
					const key = childKey ?? `carousel-item-${index.toString()}`

					return (
						<li
							style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
							className={cx(styles.item)}
							key={key}
						>
							{cloneElement(child as React.ReactElement, {
								draggable: false,
							})}
						</li>
					)
				})}
			</ul>
		)
	}
)

Items.displayName = 'Items'
