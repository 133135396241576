'use client'

import { sendSelectPromotionEvent } from '../../events/eec/selectPromotion/selectPromotion'
import type { Promotion } from '../../types/EEC'

type SelectPromotionProps = {
	children: React.ReactNode
	promotion: Promotion
}

export const SelectPromotion = ({
	promotion,
	children,
}: SelectPromotionProps) => {
	function handleClick() {
		sendSelectPromotionEvent(promotion)
	}

	// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
	return <div onClick={handleClick}>{children}</div>
}
