import { type RefObject, useEffect, useState } from 'react'

export function useActiveLink<T extends HTMLElement>(ref: RefObject<T>) {
	const [activeLink, setActiveLink] = useState<boolean>(false)

	function handleMouseDown(e: MouseEvent) {
		if (e.button === 0) {
			e.preventDefault()
			e.stopPropagation()
			setActiveLink(true)
		}
	}

	function handleMouseUp(_e: MouseEvent) {
		setActiveLink(false)
	}

	useEffect(() => {
		const element = ref?.current
		if (element) {
			element.addEventListener('mousedown', handleMouseDown)

			if (activeLink) {
				document.addEventListener('mouseup', handleMouseUp)
			}
		}

		return () => {
			if (element) {
				element.removeEventListener('mousedown', handleMouseDown)
			}
			document.removeEventListener('mouseup', handleMouseUp)
		}
	}, [activeLink])

	return activeLink
}
