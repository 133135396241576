import type { CarouselDirection, CarouselMeasures } from '../types'

export const MIN_POSITION = 0
const DIRECTION_MULTIPLIER = {
	FORWARD: {
		RTL: -1,
		LTR: 1,
	},
	BACKWARD: {
		RTL: 1,
		LTR: -1,
	},
}

export function transformDirectionToValue(
	direction: CarouselDirection,
	isRtl: boolean
): number {
	const isRtlDirection = isRtl ? 'RTL' : 'LTR'

	return DIRECTION_MULTIPLIER[direction][isRtlDirection]
}

export function calculateNewPosition(
	position: number,
	maxPosition: number
): number {
	if (position < MIN_POSITION) {
		return MIN_POSITION
	}
	if (position > maxPosition) {
		return maxPosition
	}
	return position
}

export function getNewMeasures(
	element: HTMLUListElement | null
): CarouselMeasures | null {
	if (!element) {
		return null
	}
	const viewportWidth = element.clientWidth
	const carouselWidth = element.scrollWidth
	const elementWidth = element.children[0]?.clientWidth || 0
	const numElements = element.children.length
	const computedStyle = window.getComputedStyle(element, '')
	const gap = Number(computedStyle.gap.replace('px', '')) || 0
	const padding =
		Number(computedStyle.paddingInlineStart.replace('px', '')) || 0

	return {
		viewportWidth,
		carouselWidth,
		elementWidth,
		numElements,
		gap,
		padding,
	}
}
