'use client'

import { ButtonIconSecondary } from 'fukku/Button/Icon/Secondary'
import { useHeaderHeight } from 'hooks/useHeaderHeight/useHeaderHeight'
import { IconCloseMedium } from 'icons/components/IconCloseMedium'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { OrderStatusLink } from 'order-status-banner/components/OrderStatusLink/OrderStatusLink'
import { OrderStatusStep } from 'order-status-banner/components/OrderStatusStep/OrderStatusStep'
import type { PurchaseDetail } from 'order-status-banner/types/purchase/purchase'
import { useEffect, useMemo, useState } from 'react'
import { ScrollToTop } from 'utils/ScrollToTop'
import { setToLocalStorage } from 'utils/webStorage/localStorage'

import { sendAnalytics } from '../../analyticsEvents/analyticsEvents'
import {
	AnalyticsActionType,
	LOCAL_STORAGE_BANNER_KEY,
} from '../../utils/constants'
import { hasAlertBeenClosed } from '../../utils/hasAlertBeenClosed/hasAlertBeenClosed'

import styles from './OrderStatusBanner.module.scss'

interface OrderStatusBannerProps {
	purchaseDetails: PurchaseDetail
}

export function OrderStatusBanner({ purchaseDetails }: OrderStatusBannerProps) {
	const masterData = useMasterData()
	const { t } = useLabels()
	const { isMobile } = masterData

	const [isBannerClosed, setIsBannerClosed] = useState(false)
	const { headerHeight } = useHeaderHeight()
	const { orders } = purchaseDetails

	const onCloseBanner = () => {
		// TODO: Handle close banner with server call
		setToLocalStorage(LOCAL_STORAGE_BANNER_KEY, purchaseDetails)
		setIsBannerClosed(true)
	}

	const handleClickCloseBanner = () => {
		sendAnalytics({
			purchaseDetails,
			action: AnalyticsActionType.CLOSE,
		})

		onCloseBanner()
	}

	const hasAlertBeenAlreadyClosed = useMemo(
		() => hasAlertBeenClosed(orders),
		[orders]
	)

	useEffect(() => {
		if (!hasAlertBeenAlreadyClosed && orders?.length > 0) {
			sendAnalytics({
				purchaseDetails,
				action: AnalyticsActionType.SHOW,
			})
		}
	}, [purchaseDetails])

	if (isBannerClosed || orders?.length === 0 || hasAlertBeenAlreadyClosed) {
		return null
	}

	return (
		<div
			className={styles.banner}
			data-testid='orderStatusBanner'
			{...(!isMobile && { style: { top: headerHeight } })}
		>
			<div className={styles.content} data-testid='orderStatusBanner.content'>
				<ButtonIconSecondary
					className={styles.buttonClose}
					onClick={handleClickCloseBanner}
					data-testid='orderStatusBanner.closeButton'
				>
					<IconCloseMedium
						alt={t('accessibility.commsBanner.myPurchases.close.button')}
					/>
				</ButtonIconSecondary>
				{purchaseDetails && (
					<OrderStatusStep purchaseDetails={purchaseDetails} />
				)}
				{purchaseDetails && (
					<OrderStatusLink
						onCloseBanner={onCloseBanner}
						purchaseDetails={purchaseDetails}
					/>
				)}
				<ScrollToTop />
			</div>
		</div>
	)
}
