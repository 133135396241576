// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconStoreL = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='store-l'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M19 3H5v17H3v1h18v-1h-2zm-1 7h-3V4h3zm-8 0V4h4v6zM6 4h3v6H6zm0 7h12v2H6zm0 3h12v6h-4v-4h-4v4H6zm5 6v-3h2v3z' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
