export const enum OrderType {
	MultiWarehouse = 'multi_warehouse',
	MultiOrder = 'multi_order',
	Unique = 'unique',
}

export const enum AnalyticsActionType {
	SHOW = 'show',
	CLOSE = 'close',
	CLICK = 'click',
}

export const LOCAL_STORAGE_BANNER_KEY = 'order-status-banner'
