'use client'

import { SelectPromotion } from 'analytics/components/SelectPromotion/SelectPromotion'
import { ClientTextLink } from 'fukku/TextLink/client'
import { SERVER_LINK_TYPE } from 'fukku/TextLink/server'
import type { CtaShop } from 'landings/Home/Home.type'
import { applyStylesIf, cx } from 'utils/cx'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerShopCtas.module.scss'
import texts from 'fukku/styles/texts.module.scss'

interface HeroBannerShopCtasProps {
	sectionCtas: CtaShop[]
	inverse: boolean
}

export function HeroBannerShopCtas({
	sectionCtas,
	inverse,
}: HeroBannerShopCtasProps) {
	const textClassNames = cx(
		animationStyles.opacityFadeIn,
		styles.heroBannerShopCtaText,
		texts.uppercaseM,
		applyStylesIf(inverse, styles.inverse)
	)

	if (sectionCtas.length === 1) {
		return <div className={textClassNames}>{sectionCtas[0].text}</div>
	}
	if (sectionCtas.length > 1) {
		return (
			<nav className={styles.heroBannerShopCtas}>
				{sectionCtas.map((cta) => {
					return (
						<SelectPromotion key={cta.ctaId} promotion={cta.promotion}>
							<ClientTextLink
								href={cta.href}
								className={cx(animationStyles.opacityFadeIn, styles.ctaLink)}
								linkProps={cta.linkProps}
								type={SERVER_LINK_TYPE.INSPIRATIONAL}
								inverse={inverse}
								isLocalizedPath={cta.isLocalizedPath}
							>
								{cta.text}
							</ClientTextLink>
						</SelectPromotion>
					)
				})}
			</nav>
		)
	}
	return null
}
