'use client'

import { createContext, useEffect, useMemo, useState } from 'react'

type MarqueeContextType = {
	isAnimationPaused: boolean
	toggleAnimation: () => void
}

interface MarqueeProviderProps {
	children: React.ReactNode
}

export const MarqueeContext = createContext<MarqueeContextType>({
	isAnimationPaused: false,
	toggleAnimation: () => {},
})

export const MarqueeProvider = ({ children }: MarqueeProviderProps) => {
	const [isAnimationPaused, setIsAnimationPuased] = useState(false)

	const toggleAnimation = () => {
		setIsAnimationPuased((prevState) => !prevState)
	}

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

		mediaQuery.addEventListener('change', toggleAnimation)
		setIsAnimationPuased(mediaQuery.matches)

		return () => {
			mediaQuery.removeEventListener('change', toggleAnimation)
		}
	}, [])

	const providerValues = useMemo(
		() => ({
			isAnimationPaused,
			toggleAnimation,
		}),
		[isAnimationPaused]
	)

	return (
		<MarqueeContext.Provider value={providerValues}>
			{children}
		</MarqueeContext.Provider>
	)
}
