export enum Alert {
	SENT_TRANSPORT = 'sent_transport',
	PEND_PICKING = 'pend_picking',
	ERR_TRANSPORT = 'err_transport',
	PICK_START = 'pick_start',
	IN_PREPARATION = 'in_preparation',
	PACK_START = 'pack_start',
	PICK_END = 'pick_end',
	TRAS_DIRECT_WAREHOUSE = 'tras_direct_warehouse',
	PACK_KO = 'pack_ko',
	PACK_ERROR = 'pack_error',
	WAREHOUSE_DELAY = 'warehouse_delay',
	CANCEL = 'cancel',
	PACK_END = 'pack_end',
	TRANS_PRE_ALERT = 'trans_pre_alert',
	SHIPPED = 'shipped',
	SHIPPED_TRUCK = 'shipped_truck',
	SHIPPED_INT = 'shipped_int',
	TRANS_COMF_DES_WAREHOUSE = 'trans_comf_des_warehouse',
	STORE_SHIP = 'store_ship',
	TRANS_RECEIVED = 'trans_received',
	TRANS_IN_TRANSIT = 'trans_in_transit',
	TRANS_IN_DESTINATION = 'trans_in_destination',
	TRANS_RETAINED_CUSTOMS = 'trans_retained_customs',
	TRANS_ON_DELIVERY = 'trans_on_delivery',
	TRANS_FIRST_TRY = 'trans_first_try',
	TRANS_ABSENT = 'trans_absent',
	TRANS_WRONG_ADDR = 'trans_wrong_addr',
	TRANS_DAMAGED = 'trans_damaged',
	TRANS_LOST = 'trans_lost',
	TRANS_PENDING_PICKUP = 'trans_pending_pickup',
	TRANS_CONC_DELIVERY = 'trans_conc_delivery',
	TRANS_DELAY = 'trans_delay',
	TRANS_OTHER_ISSUE = 'trans_other_issue',
	TRANS_REFUSED = 'trans_refused',
	DELIVERY_FAILURE = 'delivery_failure',
	STORE_COMFIRM = 'store_comfirm',
	TRANS_IN_STORE = 'trans_in_store',
	TRANS_RETURN_START = 'trans_return_start',
	TRANS_RETURN_END = 'trans_return_end',
	TRANS_DELIVERED = 'trans_delivered',
	COD_DELIVERED = 'cod_delivered',
	TRANS_COD_DELIVERED = 'trans_cod_delivered',
	DELIVERED = 'delivered',
}
