'use client'

import type { HeroBannerSecondaryLines } from 'landings/Home/Home.type'
import { useRef } from 'react'
import { applyStylesIf, cx } from 'utils/cx'
import { env } from 'utils/envManager'

import type { SectionDataType } from '../../../types/sectionData'
import { useActiveLink } from '../ctas/hooks/onActiveLink'
import { HeroBannerSecondaryLinesImage } from './HeroBannerSecondaryLinesImage/HeroBannerSecondaryLinesImage'

import ctaStyles from '../ctas/SingleCtaOutlet.module.scss'
import styles from './HeroBannerSecondaryLines.module.scss'

type HeroBannerSecondaryLinesComponentProps = {
	section: HeroBannerSecondaryLines
	sections: SectionDataType[]
	isPrioritySlot: boolean
}

export function HeroBannerSecondaryLinesContent({
	section,
	sections,
	isPrioritySlot,
}: HeroBannerSecondaryLinesComponentProps): React.ReactNode {
	const { title, subTitle, image, simpleCta } = section
	const ref = useRef<HTMLAnchorElement>(null)
	const activeLink = useActiveLink(ref)

	return (
		<a
			href={`${env.NEXT_PUBLIC_CURRENT_ORIGIN}${sections[0].url}`}
			className={styles.bannerWrapper}
			ref={ref}
		>
			<HeroBannerSecondaryLinesImage image={image} priority={isPrioritySlot} />
			<div className={styles.bannerContainer}>
				<h2 className={styles.bannerTitles}>
					<p className={styles.subTitle}>{subTitle}</p>
					<div className={styles.title}>{title}</div>
				</h2>
				<div>
					<div
						className={cx(
							ctaStyles.singleCta,
							applyStylesIf(activeLink, ctaStyles.active)
						)}
					>
						{simpleCta.labelKey}
					</div>
				</div>
			</div>
		</a>
	)
}
