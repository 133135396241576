import type { IconProps } from 'icons/types/Icon.types'
import React from 'react'
import { cx } from 'utils/cx'

import { BUTTON_VARIANT } from '../../Button.types'
import { ButtonBase } from '../../ButtonBase'

import styles from './ButtonIconFloating.module.scss'

interface FloatingProps extends React.ComponentProps<'button'> {
	variant?: BUTTON_VARIANT
	children: React.ReactElement<IconProps>
}

const FLOAT_SVG_SIZE = 16

export function ButtonIconFloating(props: FloatingProps): React.ReactNode {
	const {
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		children,
		...rest
	} = props

	const childrenWithProps = React.Children.map(children, (child) =>
		React.isValidElement(child)
			? React.cloneElement(child, {
					width: FLOAT_SVG_SIZE,
					height: FLOAT_SVG_SIZE,
				})
			: child
	)

	return (
		<ButtonBase
			{...rest}
			className={cx(className, styles[variant], styles.noBorder)}
		>
			<div className={cx(styles.innerBox)}>{childrenWithProps}</div>
		</ButtonBase>
	)
}
