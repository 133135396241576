'use client'

import { ServerLabel } from 'labels/server'
import { applyStylesIf, cx } from 'utils/cx'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerShopMultibrandTitle.module.scss'
import texts from 'fukku/styles/texts.module.scss'

interface HeroBannerShopMultibrandTitleProps {
	titleKey: string
	inverse: boolean
	isLargeOrGreater: boolean
}

export function HeroBannerShopMultibrandTitle({
	titleKey,
	inverse,
	isLargeOrGreater,
}: HeroBannerShopMultibrandTitleProps) {
	const textStyle = cx(
		animationStyles.opacityFadeIn,
		styles.heroBannerShopMultibrandTitle,
		applyStylesIf(inverse, styles.inverse),
		isLargeOrGreater
			? texts.inspirationalTitleXXXL
			: texts.inspirationalTitleXXL
	)

	return (
		<h2 className={textStyle}>
			<ServerLabel
				italicProps={{ className: styles.italic }}
				paragraphProps={{ className: textStyle }}
			>
				{titleKey}
			</ServerLabel>
		</h2>
	)
}
