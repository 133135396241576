import type { Promotion } from 'analytics/types/EEC'

import type {
	CommCarouselItem,
	CommsBanner,
	CrossSellingCarousel,
	FamilyBannerShop,
	HeroBannerCtas,
	HeroBannerPromo,
	HeroBannerShop,
	HeroBannerShopMultibrand,
	HeroBannerShopSales,
	HeroBannerShopType,
	LinesDirectAccess,
} from '../Home.type.ts'

type PromotionFactorySections =
	| CommsBanner
	| HeroBannerCtas
	| HeroBannerPromo
	| LinesDirectAccess
	| CrossSellingCarousel
	| CommCarouselItem
	| HeroBannerShop<HeroBannerShopType>
	| HeroBannerShopMultibrand
	| FamilyBannerShop
	| HeroBannerShopSales<HeroBannerShopType>

export function promotionFactory(
	section: PromotionFactorySections,
	slot: string
): (analyticsId: string) => Promotion {
	return (analyticsId: string) => ({
		promotion_id: section.uid,
		promotion_name: section.promotionName,
		creative_name: `${section.sectionType}-${analyticsId}`,
		creative_slot: slot,
	})
}
