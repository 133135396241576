import type { PointerEvent, WheelEvent } from 'react'

interface MobileEvents {
	onPointerDown: (e: PointerEvent<HTMLElement>) => void
	onPointerMove: (e: PointerEvent<HTMLElement>) => void
	onPointerUp: () => void
	onPointerCancel: () => void
}

interface DesktopEvents {
	onWheel: (event: WheelEvent<HTMLElement>) => void
}

interface GetHorizontalScrollEventsProps {
	isMobile: boolean
	mobileEvents: MobileEvents
	desktopEvents: DesktopEvents
}

export const getHorizontalScrollEvents = ({
	isMobile,
	mobileEvents,
	desktopEvents,
}: GetHorizontalScrollEventsProps) => (isMobile ? mobileEvents : desktopEvents)
