'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import React, {
	type PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import { Draggable } from '../components/Draggable/Draggable'
import { Header } from '../components/Header/Header'
import { Items } from '../components/Items/Items'
import {
	type CarouselDirection,
	type CarouselMeasures,
	type CarouselProps,
	CarouselVariants,
} from '../types'
import {
	MIN_POSITION,
	calculateNewPosition,
	getNewMeasures,
	transformDirectionToValue,
} from '../utils/carouselUtils'
import { useCarouselDrag } from './useCarouselDrag/useCarouselDrag'

export type CarouselContextType = {
	measures: CarouselMeasures | null
	isAt: { start: boolean; end: boolean }
	variant: CarouselVariants
	getPosition: () => number // px from the left
	updatePosition: (
		position: number,
		direction: CarouselDirection,
		behavior?: 'smooth' | 'instant'
	) => void
}

type CarouselProviderProps = PropsWithChildren<CarouselProps>

export const CarouselContext = createContext<CarouselContextType | undefined>(
	undefined
)

const ERROR_MARGIN = 2 // px to consider the carousel at the end of scroll

export function CarouselProvider({
	children,
	variant = CarouselVariants.VARIANT2,
	...headerProps
}: CarouselProviderProps): React.ReactNode {
	const {
		country: { isRtl },
	} = useMasterData()
	const carouselRef = useRef<HTMLUListElement>(null)
	const [isAt, setIsAt] = useState({ start: true, end: true })
	const [isDragging, setIsDragging] = useState(false)
	const [measures, setMeasures] = useState<CarouselMeasures | null>(
		getNewMeasures(carouselRef.current)
	)
	const drag = useCarouselDrag(measures)

	const getPosition = useCallback<CarouselContextType['getPosition']>(
		() => carouselRef.current?.scrollLeft || 0,
		[]
	)

	const updatePosition = useCallback<CarouselContextType['updatePosition']>(
		(amount, direction, behavior = 'smooth') => {
			if (measures && carouselRef.current && amount !== 0) {
				drag(carouselRef, behavior === 'instant')
				const directionValue = transformDirectionToValue(direction, isRtl)
				const actualPosition = getPosition()
				const maxPosition =
					carouselRef.current.scrollWidth - measures.viewportWidth
				const newPosition = calculateNewPosition(
					actualPosition + amount * directionValue,
					maxPosition
				)
				carouselRef.current.scrollTo({
					left: newPosition,
					behavior,
				})
			}
		},
		[measures]
	)

	const updateIsAt = useCallback(
		(newMeasures: CarouselMeasures | null) => {
			if (newMeasures && carouselRef.current) {
				const actualPosition = getPosition()
				const maxPosition =
					carouselRef.current.scrollWidth -
					newMeasures.viewportWidth -
					ERROR_MARGIN
				const isAtStart = actualPosition === MIN_POSITION
				const isAtEnd = actualPosition >= maxPosition
				const shouldUpdate = isAt.start !== isAtStart || isAt.end !== isAtEnd

				if (shouldUpdate) {
					setIsAt({ start: isAtStart, end: isAtEnd })
				}
			}
		},
		[isAt, getPosition]
	)

	useEffect(() => {
		const updateMeasuresAndIsAt = (el: HTMLUListElement | null) => {
			const newMeasures = getNewMeasures(el)
			if (newMeasures) {
				setMeasures(newMeasures)
				updateIsAt(newMeasures)
			}
		}
		const observer = new ResizeObserver((entries) => {
			const newCarousel = entries[0].target as HTMLUListElement
			updateMeasuresAndIsAt(newCarousel)
		})

		carouselRef.current && observer.observe(carouselRef.current)
		updateMeasuresAndIsAt(carouselRef.current)

		return () => {
			observer?.disconnect()
		}
	}, [updateIsAt])

	useEffect(() => {
		drag(carouselRef, isDragging)
	}, [isDragging])

	const value = useMemo(
		() => ({
			isAt,
			measures,
			variant,
			getPosition,
			updatePosition,
		}),
		[measures, isAt, getPosition, updatePosition, variant]
	)

	return (
		<CarouselContext.Provider value={value}>
			<Header variant={variant} {...headerProps} />
			<Draggable onChange={setIsDragging}>
				<Items
					isDragging={isDragging}
					variant={variant}
					onScroll={() => updateIsAt(measures)}
					ref={carouselRef}
				>
					{children}
				</Items>
			</Draggable>
		</CarouselContext.Provider>
	)
}

/** @throws {Error} If useCarousel is used without CarouselProvider */
export const useCarousel = (): CarouselContextType => {
	const context = useContext(CarouselContext)
	if (!context) {
		throw new Error('useCarousel must be used within a CarouselProvider')
	}

	return context
}
