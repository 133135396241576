import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import {
	type HTMLAttributes,
	type ReactNode,
	type RefObject,
	forwardRef,
} from 'react'
import { isBot } from 'utils/isBot/IsBot'

interface BannerWrapperProps extends HTMLAttributes<HTMLDivElement> {
	priority?: boolean
}

export const BannerWrapper = forwardRef<HTMLDivElement, BannerWrapperProps>(
	function BannerWrapper(props, ref): ReactNode {
		const divRef = ref as RefObject<HTMLDivElement>
		const { priority, children, ...rest } = props
		const entry = useIntersectionObserver(divRef, {
			threshold: 0.01,
			rootMargin: '50% 0%',
			freezeOnceVisible: true,
		})
		const showContent = entry?.isIntersecting || priority || isBot()

		return (
			<div ref={divRef} {...rest}>
				{showContent ? children : null}
			</div>
		)
	}
)
