import type {
	FetchedOrder,
	PurchaseDetail,
} from '../../types/purchase/purchase'

export const normalize = (
	purchaseDetail: FetchedOrder
): PurchaseDetail | undefined => {
	const { purchaseId, isMultiWarehouse, isMultiWarehouseTurkey, orders } =
		purchaseDetail
	if (!purchaseId) {
		return undefined
	}

	const isMultiWareHouse = !!isMultiWarehouse || !!isMultiWarehouseTurkey
	const isMultiOrder = orders?.length > 1 || isMultiWareHouse

	return {
		...purchaseDetail,
		isMultiOrder,
		isMultiWarehouse,
	}
}
