import { cx } from 'utils/cx'

import { BUTTON_VARIANT } from '../../Button.types'
import { ButtonBase } from '../../ButtonBase'

import styles from './ButtonIconSecondary.module.scss'

interface SecondaryProps extends React.ComponentProps<'button'> {
	variant?: BUTTON_VARIANT
}

export function ButtonIconSecondary(props: SecondaryProps): React.ReactNode {
	const { variant = BUTTON_VARIANT.DEFAULT, className, ...rest } = props

	return <ButtonBase {...rest} className={cx(className, styles[variant])} />
}
