// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconEnvioL = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='envio-l'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M18 9V4H2v14h3.05c.233 1.14 1.243 2 2.45 2s2.217-.86 2.45-2h5.1c.233 1.14 1.243 2 2.45 2s2.217-.86 2.45-2H22v-5.651L19.768 9zM7.5 19c-.827 0-1.5-.673-1.5-1.5S6.673 16 7.5 16s1.5.673 1.5 1.5S8.327 19 7.5 19m0-4c-1.207 0-2.217.86-2.45 2H3V5h14v10.05A2.51 2.51 0 0 0 15.05 17h-5.1a2.504 2.504 0 0 0-2.45-2m10 4c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5m3.5-2h-1.05A2.51 2.51 0 0 0 18 15.05V10h1.232L21 12.651z' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
