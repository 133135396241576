'use client'

import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'

import styles from './OrderStatusText.module.scss'

interface OrderStatusTextProps {
	status: string
	id: string
}

export const OrderStatusText = ({ status, id }: OrderStatusTextProps) => {
	const { t } = useLabels()

	return (
		<span className={styles.statusText}>
			<ClientLabel placeholderValues={{ 1: id }}>
				{t(`myPurchases.online.status_${status}.label`) ?? ''}
			</ClientLabel>
		</span>
	)
}
