import { FetchError } from 'hooks/useFetch/FetchError'

export async function handleFetchError(response: Response): Promise<void> {
	let error: Error | null = null
	try {
		error = await response.json()
	} catch (err) {
		error = err as Error
	}
	throw new FetchError(response.statusText, response.status, error as Error)
}
