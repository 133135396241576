export class FetchError extends Error {
	readonly info: unknown
	readonly status: number

	constructor(message: string, status: number, error: Error) {
		super(message)
		this.name = 'FetchError'
		this.info = error
		this.status = status
	}
}
