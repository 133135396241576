import { type RefObject, useEffect, useState } from 'react'

interface UseTitleImageOverflow {
	imageWrapperRef: RefObject<HTMLDivElement> | null
	imageAspectRatio: number | null
	imageRelativeWidth: number | null
	padding?: number
}

const ONE_PERCENT = 0.01

export const useTitleImageHeightOverflow = ({
	imageWrapperRef,
	imageAspectRatio,
	imageRelativeWidth,
	padding = 0,
}: UseTitleImageOverflow) => {
	const imageWrapper = imageWrapperRef?.current
	const applyOverflow = Boolean(
		imageWrapperRef && imageAspectRatio && imageRelativeWidth
	)
	const [isOverflowing, setIsOverflowing] = useState(false)
	const [maxHeight, setMaxHeight] = useState<number | null>(null)

	useEffect(() => {
		const handleWrapperResize = () => {
			if (
				imageWrapper?.offsetHeight &&
				applyOverflow &&
				imageRelativeWidth &&
				imageAspectRatio
			) {
				const imageContainerHeight = Math.max(
					imageWrapper.offsetHeight - padding,
					0
				)
				const isOverflowingValue = Boolean(
					typeof imageContainerHeight === 'number' &&
						(imageWrapper?.offsetWidth * imageRelativeWidth * ONE_PERCENT) /
							imageAspectRatio >
							imageContainerHeight
				)
				setMaxHeight(imageContainerHeight)
				setIsOverflowing(isOverflowingValue)
			}
		}

		handleWrapperResize()
		if (applyOverflow) {
			window?.addEventListener('resize', handleWrapperResize)
		}

		return () => {
			if (applyOverflow) {
				window?.removeEventListener('resize', handleWrapperResize)
			}
		}
	}, [applyOverflow, imageWrapper, imageAspectRatio, imageRelativeWidth])

	return {
		isImageHeightOverflowing: applyOverflow ? isOverflowing : false,
		maxHeight,
	}
}
