import { cx } from 'utils/cx'

import styles from './Ctas.module.scss'

interface TwoColumnsCtasWrapperProps {
	absolute?: boolean
	children: React.ReactNode
}

export function TwoColumnsCtasWrapper({
	children,
	absolute = false,
}: TwoColumnsCtasWrapperProps) {
	return (
		<div className={cx(styles.ctasCenterWrapper, absolute && styles.absolute)}>
			<nav className={styles.ctasWrapper}>{children}</nav>
		</div>
	)
}
