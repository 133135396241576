'use client'

import { useDraggable } from 'landings/hooks/useDraggable/useDraggable'
import { useScrollAnalytics } from 'landings/hooks/useScrollAnalytics/useScrollAnalitics'
import { Carousels } from 'landings/types/Carousels.type'
import { useCallback, useEffect, useRef, useState } from 'react'
import { cx } from 'utils/cx'

import styles from './ScrollView.module.scss'

interface ScrollViewProps {
	children: React.ReactNode[]
	className?: string
}

const SCROLLABLE_GAP = 24

export const ScrollView = ({ children, className }: ScrollViewProps) => {
	const scrollWrapperRef = useRef<HTMLDivElement>(null)
	const [isCenteredInView, setIsCenteredInView] = useState(false)

	const { sendScrollAnalytic } = useScrollAnalytics()

	const { onMouseDown } = useDraggable(scrollWrapperRef, {
		onDrag: () => {
			sendScrollAnalytic(Carousels.FAMILIES_CAROUSEL)
		},
	})

	const handleViewportResize = useCallback(() => {
		if (scrollWrapperRef.current) {
			const scrollWrapperWidth =
				(scrollWrapperRef.current.children[0].clientWidth + SCROLLABLE_GAP) *
				children.length
			const viewportWidth = scrollWrapperRef.current.offsetWidth

			setIsCenteredInView(viewportWidth > scrollWrapperWidth)
		}
	}, [])

	useEffect(() => {
		handleViewportResize()
		const resizeObserver = new ResizeObserver(handleViewportResize)

		if (scrollWrapperRef.current) {
			resizeObserver.observe(scrollWrapperRef.current)
		}

		return () => resizeObserver.disconnect()
	}, [scrollWrapperRef.current])

	return (
		<div
			role='presentation'
			className={cx(
				styles.scrollable,
				className,
				isCenteredInView && styles.justifyCenter
			)}
			onMouseDown={onMouseDown}
			ref={scrollWrapperRef}
		>
			{children}
		</div>
	)
}
