import type { Promotion } from '../../../types/EEC'
import { pushToDatalayer } from '../../../utils/pushToDatalayer/pushToDatalayer'

export const sendViewPromotionEvent = (promotions: Promotion[]): void => {
	promotions.forEach((promotion) => {
		const payload = {
			event: 'GA4-EEC-viewPromotion',
			event_name: 'view_promotion',
			view_promotion: {
				...promotion,
				genesis: true,
			},
		}
		pushToDatalayer(payload)
	})
}
