'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'
import { StatusCode } from 'types/statusCodes'
import { handleFetchError } from 'utils/fetchError/handleFetchError'

export const useFetch = () => {
	const { token } = useSession()

	const {
		country: { locale },
	} = useMasterData()

	async function fetcher<T>({
		url,
		method = 'GET',
		body,
		extraHeaders,
	}: {
		url: string
		method?: string
		body?: unknown
		extraHeaders?: Record<string, string>
	}): Promise<T | null> {
		if (!url) {
			return null
		}
		return new Promise((resolve, reject) => {
			fetch(url, {
				method,
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					...extraHeaders,
				},
				body: JSON.stringify(body),
				next: { revalidate: 0 },
			})
				.then(async (response) => {
					if (!response.ok) {
						await handleFetchError(response)
					}

					if (response.status === StatusCode.NO_CONTENT) {
						return resolve(null)
					}

					const data = await response.json()
					return resolve(data as T)
				})
				.catch((error) => {
					console.error(error)
					return reject(error)
				})
		})
	}

	return { fetcher }
}
