import { IconNuevaVentana } from 'icons/components/IconNuevaVentana'
import { ServerLink, type ServerLinkProps } from 'utils/link/server'

import { type TextLinkClassNameProps, getTextLinkClassName } from '../utils'

export type ServerTextLinkProps = ServerLinkProps & TextLinkClassNameProps

export function ServerTextLink({
	type,
	size,
	disabled,
	active,
	unselected,
	inverse,
	withIcon,
	className,
	children,
	...props
}: ServerTextLinkProps) {
	const serverTextLinkClassName = getTextLinkClassName({
		className,
		type,
		size,
		disabled,
		active,
		unselected,
		inverse,
		withIcon,
	})

	return (
		<ServerLink className={serverTextLinkClassName} {...props}>
			{children}
			{withIcon && <IconNuevaVentana width={14} height={14} />}
		</ServerLink>
	)
}
